export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"}],"link":[{"rel":"stylesheet","media":"print","onload":"this.onload=null;this.removeAttribute('media');","href":"/fonts.css"},{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://a.storyblok.com"}],"style":[],"script":[{"src":"https://cdn-ukwest.onetrust.com/scripttemplates/202401.1.0/otBannerSdk.js","defer":true},{"src":"https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js","data-document-language":true,"data-domain-script":"7998422b-d6c3-4066-9168-c124b099521e","defer":true},{"innerHTML":"function OptanonWrapper() { }"},{"hid":"gtmHead","innerHTML":"\n                    window.onload = function() {\n                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n                        })(window,document,'script','dataLayer','GTM-WCM3ZFH');\n                    };\n                    "}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"externalRelAttribute":"","componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false