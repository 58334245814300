import payload_plugin_AOLAe8jdNt from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_UmYBc4oQYf from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Az9LGNZTY9 from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_b2k1bQvm2d from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pgmVeyzIwm from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2._4mxxcdcvel2m24ajqjkv2zgmla/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_0a1Usgsu4r from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HP5zFP5GFJ from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_r78wqff7qJ from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3ghEwCmRZL from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_1plRegEA2K from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/.nuxt/components.plugin.mjs";
import prefetch_client_o1wKKtCcUk from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__xqodm7bjc3hn2adjkpfxqhuxju/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_boEY3adLc0 from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_te_l2cj2dwsj5nl24pd2nyhg23hta/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_plugin_pn403JGvsx from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.32.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_5clynZCQYE from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_inj9Wq4or1 from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.32.0_typescript@5_5kdbwcp3htlhi6kx6eqatbpu5m/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_oidg3GmVLq from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_U8sTvYoskL from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_2hii42QBJd from "/codebuild/output/src3713716597/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  payload_plugin_AOLAe8jdNt,
  revive_payload_client_UmYBc4oQYf,
  unhead_Az9LGNZTY9,
  router_b2k1bQvm2d,
  _0_siteConfig_pgmVeyzIwm,
  payload_client_0a1Usgsu4r,
  navigation_repaint_client_HP5zFP5GFJ,
  check_outdated_build_client_r78wqff7qJ,
  chunk_reload_client_3ghEwCmRZL,
  plugin_vue3_1plRegEA2K,
  components_plugin_KR1HBZs4kY,
  prefetch_client_o1wKKtCcUk,
  plugin_OHU0vggoM9,
  plugin_boEY3adLc0,
  nuxt_plugin_pn403JGvsx,
  plugin_5clynZCQYE,
  plugin_inj9Wq4or1,
  switch_locale_path_ssr_oidg3GmVLq,
  i18n_U8sTvYoskL,
  base64_2hii42QBJd
]